<template>
  <div class="mechines bg-secondary">
    <div class="alert" role="alert" id="snackbar1">
        <strong>Success: </strong>Message sent. If not open.<br> <a href="http://t.me/m4a_portal_bot">http://t.me/m4a_portal_bot</a> Click start.
    </div>
    <div class="alert" role="alert" id="snackbar2">
        <strong>Success: </strong>Test message was sent to your email.
    </div>
  <h1 class="">Machines Users</h1>
  <router-link 
  tag="button"
  v-if="userType == 'Admin' || userType == 'Super Admin'"
  to="/AddUser" 
  class="btn-sm btn-info" 
  id="addUser">
   Add User
  </router-link>
  <table class="table table-hover table-dark">
  <thead>
    <tr>
      <th scope="col">FullName</th>
      <th scope="col">Email</th>
      <th scope="col">User Type</th>
      <th scope="col">Phone No</th>
      <th scope="col">Telegram Username</th>
      <th scope="col">Added</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="user in users" :key="user.ID">
      <td>{{ user.fullname }}</td>
      <td>{{ user.email }}
        <i 
         class="fas fa-check-square btn btn-info btn-sm"
         @click="test_msg_email(user.email)">
         <span class="tooltiptext">Test email message</span>
        </i>
      </td>
      <td>{{ user.userType }}</td>
      <td>{{ user.tel }}</td>
      <td>
        {{ user.telegram }}
         <i 
         class="fa fa-check-square btn btn-info btn-sm"
         @click="test_msg(user.telegram)">
         <span class="tooltiptext">Test Telegram message</span>
         </i>
      </td>
      <td>{{ user.created}}</td>
      <td>
        <!-- SUBSCRIBE NOTES EMAIL -->
       <button 
          id="button"
          class="btn-info btn-sm"
          :class="'_'+user.email_notification"
          @click="subscribe_notes_email(user)">
          <i class="fa fa-envelope"></i>
       </button>
       <!-- SUBSCRIBE NOTES TELEGRAM  -->
       <button 
          id="button"
          class="btn-info btn-sm"
          :class="'_'+user.telegram_notification"
          @click="subscribe_notes_telegram(user)">
          <i class="fa-solid fa-t">T</i>
       </button>

       <router-link 
       v-if="userType == 'Admin' || userType == 'Super Admin'"
       tag="button" 
       :to="{ name: 'EditViewUser', params: { user }}" 
       class="btn btn-info btn-sm">
       <i class="fa fa-pen"></i>
       </router-link>

      <button 
      v-if="userType == 'Admin' || userType == 'Super Admin'"
      class="btn btn-info btn-sm" 
      @click="deleteUser(user)">
      <i class="fa fa-trash-alt"></i>
      </button>
      </td>
    </tr>
  </tbody>
</table>
  </div>
</template>
<script>
import axios from 'axios'
export default {
    data () {
      return {
        users: [],
        userType: '',
        telegram_message: '',
      }
    },
  mounted() {
   this.getUserDetails()
  },

  methods: {
    //SELECT ALL USERS
    allUsersGet() {
      axios.get('https://m4a.ramacsystems.com/backend/users/addUsers.php')
        .then((res) => {
          let unfilterdUsers = res.data
          if(this.userType == 'Super Admin') {
            this.users = unfilterdUsers
          } 
          if (this.userType == 'Admin') {
             this.users = unfilterdUsers.filter(user => user.userType == "Admin" || user.userType == "Manager");
          } 
          if(this.userType == 'Manager') {
             this.users = unfilterdUsers.filter(user => user.userType == "Manager");
          }
          if(this.userType == 'viewer') {
             this.users = unfilterdUsers.filter(user => user.userType == "viewer");
          }
        })
        .catch(function (error) {
            console.log(error)
        })
    },

    //TELEGRAM SEND TEST MESSAGE
    test_msg(telegram) {
      // console.log(telegram)
      axios.get('https://m4a.ramacsystems.com/backend/notifications/telegram_test_msg.php?params', {
        params: {
          telegram: telegram
        }
      })
      .then((res)=> {
          console.log(res)
          var x = document.getElementById("snackbar1");
          x.className = "show";
          setTimeout(function(){ 
          x.className = x.className.replace("show", "");
        }, 4000);
      })

      .catch((error) =>{
         console.log(error)
          var x = document.getElementById("snackbar1");
          x.className = "show";
          setTimeout(function(){ 
          x.className = x.className.replace("show", "");
        }, 4000);
      })
    },

    //EMAIL SEND TEST MESSAGE
    test_msg_email(email) {
      console.log(email)
      axios.get('https://m4a.ramacsystems.com/backend/notifications/email_example.php?params', {
        params: {
          email: email
        }
      })
      .then((res)=> {
          console.log(res)
          var x = document.getElementById("snackbar2");
          x.className = "show";
          setTimeout(function(){ 
          x.className = x.className.replace("show", "");
        }, 4000);
      })
      .catch((error) =>{
         console.log(error)
          var x = document.getElementById("snackbar2");
          x.className = "show";
          setTimeout(function(){ 
          x.className = x.className.replace("show", "");
        }, 4000);
      })
    },

   //SUBSCRID TELEGRAM NOTIFICATIONS
    subscribe_notes_telegram(user) {
      //IF USER IS NOT SUBSCRIBED 
      if(user.telegram_notification == 0)
        {
          let id = user.id
          let telegram_notification = 1
          let formData = new FormData();
          formData.append('id', id);
          formData.append('telegram_notification', telegram_notification);
          formData.append('action', 'telegram_notification');
            axios({
                 url: 'https://m4a.ramacsystems.com/backend/users/subscribe.php',
                 method: 'post',
                 data: formData,
                 config: { headers: { 'Content-Type' : 'multipart/formdata'}},
             })
             .then(() => {
               this.allUsersGet();
             })
        }
        //IF USER IS SUBSCIDED 
       if(user.telegram_notification == 1)
        {
          let id = user.id
          let telegram_notification = 0
          let formData = new FormData();
          formData.append('id', id);
          formData.append('telegram_notification', telegram_notification);
          formData.append('action', 'telegram_notification');
            axios({
                 url: 'https://m4a.ramacsystems.com/backend/users/subscribe.php',
                 method: 'post',
                 data: formData,
                 config: { headers: { 'Content-Type' : 'multipart/formdata'}},
             })  
           .then(() => {
             this.allUsersGet();
           })   
       }
    },

    //SUBSCRIBE EMAIL NOTIFICATIONS
    subscribe_notes_email(user) {
      //IF USER IS NOT SUBSCRIBED 
      if(user.email_notification == 0)
        {
          let id = user.id
          let email_notification = 1
          let formData = new FormData();
          formData.append('id', id);
          formData.append('email_notification', email_notification);
          formData.append('action', 'email_notification');
            axios({
                 url: 'https://m4a.ramacsystems.com/backend/users/subscribe.php',
                 method: 'post',
                 data: formData,
                 config: { headers: { 'Content-Type' : 'multipart/formdata'}},
             })
             .then(() => {
               this.allUsersGet();
             })
        }
        //IF USER IS SUBSCIDED 
       if(user.email_notification == 1)
        {
          let id = user.id
          let email_notification = 0
          let formData = new FormData();
          formData.append('id', id);
          formData.append('email_notification', email_notification);
          formData.append('action', 'email_notification');
            axios({
                 url: 'https://m4a.ramacsystems.com/backend/users/subscribe.php',
                 method: 'post',
                 data: formData,
                 config: { headers: { 'Content-Type' : 'multipart/formdata'}},
             })  
           .then(() => {
             this.allUsersGet();
           })   
       }
    },

  //GET USER DETAILS
    getUserDetails() {
      let userDetails = localStorage.getItem('userType')
      this.userType = userDetails
      this.allUsersGet();
    },
    //DELETE USER
    deleteUser(user) {
        var r = confirm("Are you sure you want to delete this user ?");
        if (r == true) {
        let id = user.id  
        let fullname = localStorage.getItem('fullname') 
        let user_action = 'Delete user'
        let description = fullname+" deleted " + user.fullname
        axios.get('https://m4a.ramacsystems.com/backend/users/deleteUser.php?params', {
          params: {
            id: id,
            fullname: fullname,
            user_action: user_action,
            description: description
          },
        })
        .then(() => {
            this.allUsersGet()
        })
     } else {
       return
        }
    },
  },
}
</script>
<style scoped>
.mechines,
h1,
td,
th {
    color: rgb(255, 255, 255);
}

h1 {
    float: left;
    margin-left: 10px;
    text-transform: uppercase;
    font-size: 20px;
}

#addUser {
  float: right;
  margin-right: 90px;
}

button {
  margin: -10px 10px -10px 0px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #5bc0de;
}

input:focus + .slider {
  box-shadow: 0 0 1px #5bc0de;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.fa {
  color: #fff;
}
._1 {
  background-color: #28a745;
  border: none;
  border-radius: 10%;
  padding: 2%  4%  1.5%  4%;
  /* margin-bottom: -10px !important; */
}

._0 {
  background-color: #17a2b8;
  border: none;
  border-radius: 10%;
  padding: 2%  4%  2%  4%;
  /* margin-bottom: -100px !important; */
}

._0:hover,
._0:active {
  background-color: #127b8b;
}
._1:hover,
._1:active {
  background-color: #1b6e2e;
}

.btn-info .tooltiptext {
  visibility: hidden;
  width: 190px;
  background-color: darkgrey	;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 1px 0;
  margin-left: -70px;
  margin-top: 25px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.btn-info:hover .tooltiptext {
  visibility: visible;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
}
.email_pass_reset {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 5px;
}
#snackbar1 {
	visibility: hidden;
	min-width: 250px;
  margin-left: -125px;
  text-align: center;
  background-color: #33a71b !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar1.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

#snackbar2 {
	visibility: hidden;
	min-width: 250px;
  margin-left: -125px;
  text-align: center;
  background-color: #33a71b !important;
	border-radius: 2px;
	padding: 5px;
	position: fixed;
	z-index: 1;
	left: 50%;
    top: 10px;
	font-size: 17px;
  }
  
  #snackbar2.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
</style>