<template>
    <div class="wrapper bg-secondary">
        <div class="sidebar bg-secondary">
            <img class="m4a_img" src="../assets/m4a.png">
            <ul>
                <router-link tag="li" to="/"><i class="fas fa-home"></i>Home</router-link>
                <!-- CONFIG DROPDOWN -->
                  <ul class="dropdown">
                  <li 
                  class="dropdown-toggle"  
                  id="menu1" data-toggle="dropdown"> 
                  <i class="fa fa-cog"></i>Configuration<span class="caret"></span></li>
                  <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                    <router-link tag="li" to="/Extruder"><i class="fas fa-mouse-pointer"></i>Extruder</router-link>
                    <router-link tag="li" to="/Compression"><i class="fas fa-mouse-pointer"></i>Compression</router-link>
                    <router-link tag="li" to="/Injection"><i class="fas fa-mouse-pointer"></i>Injection</router-link>
                    <router-link tag="li" to="/Corrugator"><i class="fas fa-mouse-pointer"></i>Corrugator</router-link>
                    <router-link tag="li" to="/Products"><i class="fas fa-mouse-pointer"></i>Products</router-link>
                    <router-link tag="li" to="/MachineStatus"><i class="fas fa-mouse-pointer"></i>MachineStatus</router-link>
                  </ul>
                </ul>
                <router-link tag="li" to="/Users"><i class="fas fa-users"></i>Users</router-link>
                <router-link 
                v-if="userType == 'Super Admin'"
                tag="li" 
                to="/DeviceList">
                <i class="fa fa-hdd"></i>Devices</router-link>
                <router-link v-if="userType == 'Super Admin'" tag="li" to="/Audit"><i class="fas fa-audio-description"></i>Audit Log</router-link>
                <li @click="logOut"><i class="fas fa-power-off"></i>LogOut</li>
            </ul>
            <div class="footer ">
                <p 
                  class="g-matrix" 
                  style="color: #bdb8d7;   font-size: 15px;">Powerd by <br>
                  <a href="http://g-matrixsystems.com/" target="_blank">
                    G-Matrix Systems
                  </a>
                </p>
               <router-link tag="a" to="/help" class="help">Help</router-link>
            </div>
        </div>
        <div class="main_content bg-secondary">
            <div class="header"><h1>m4a PRODUCTION MONITORING</h1></div>  
                  <div class="info bg-secondary">
                  <transition name="moveInUp">
                    <router-view/>
                   </transition> 
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      userType: ''
    }
  },

mounted() {
  this.getUserDetails()
},

methods: {
  //LOGOUT 
    logOut() {
        this.$store.dispatch('destroyToken'),
        location.reload()
        },
    //GET USER DETAILS
    getUserDetails() {
      let userDetails = localStorage.getItem('userType')
      this.userType = userDetails
    },
   }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  /* font-family: 'Josefin Sans', sans-serif; */
}

#submenu2{
  width: 10px;
}
.wrapper{
  display: flex;
  position: relative;
}

.wrapper .sidebar{
  width: 150px;
  height: 100%;
  padding: 20px 0px;
  position: fixed;
  height: 100vh;
}

.wrapper .sidebar h2{
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
}

.wrapper .sidebar ul li{
  padding: 7px;
  border-bottom: 1px solid #ffffff;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  border-top: 1px solid rgba(255,255,255,0.05);
  float: left;
  margin-left: 1px;
}    

.dropdown-toggle {
  padding: 15px;
  border-bottom: 1px solid #ffffff;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  border-top: 1px solid rgba(255,255,255,0.05);
  float: left;
  margin-left: 1px;
}    

.wrapper .sidebar ul li {
  color: #bdb8d7;
}

.caret {
  color: #bdb8d7;
}

.wrapper .sidebar ul li a .fas
{
  width: 25px;
}

.wrapper .sidebar ul li:hover
{
  background-color: #232d42c4;
  width: 150px;
}
    

.wrapper .main_content
{
  width: 100%;
  margin-left: 130px;
}

.wrapper .main_content .header
{
  padding: 20px;
  color: #000000;
  border-bottom: 1px solid #e0e4e8;
}

.wrapper .main_content .info{
  margin: 20px;
  color: #717171;
  line-height: 25px;
}

.wrapper .main_content .info div{
  margin-bottom: 20px;
}

.fa-mouse-pointer {
  -ms-transform: rotate(115deg); /* IE 9 */
  transform: rotate(115deg);
  margin-right: 10px;
  font-size: 10px;
}
img {
  width: 70px;
  height: 65px;
  border-radius: 50%;
  margin-top: 0px;
}

.header h1 {
  font-style: bold;
  font-size: 30px;
  margin: 0px;
  color: #bdb8d7;
}

.main_content {
  object-fit: fill;
  height: 101vh;
  margin-top: -10px;
}
a .fa-industry {
    color: #bdb8d7;
    display: inline !important;
}

@media only screen and (max-width:1920) {
  .main_content {
    object-fit: fill;
    height: 101vh;
  }
}

@media only screen and (min-width: 1920px) {
   .sidebar .footer  {
    margin-top: 520% !important;
  }
}


.moveInUp-enter-active{
  opacity: 0;
  transition: opacity 1s ease-in;
}

.moveInUp-enter-active{
  animation: fadeIn 1s ease-in;
}

@keyframes fadeIn{
  0%{
    opacity: 0;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    opacity: 1;
  }
}

.moveInUp-leave-active{
  animation: moveInUp .3s ease-in;
}
@keyframes moveInUp{
 0%{
  transform: translateY(0);
 }
  100%{
  transform: translateY(-400px);
 }
}

.sidebar .footer p {
  margin-top: -20%;
  float: left;
  margin-left: -10px;
}

.sidebar .footer a {
  color: #7ff847;
  text-decoration: none;
  margin-left: 15px;
  float: left;
}

.sidebar .footer .help  {
  color: #FF8700;
  float: left;
}
</style>